










import { Component, Prop, Vue } from 'vue-property-decorator';
@Component({
  name: 'navLayout'
})
export default class InsNavLayout2 extends Vue {
  @Prop() private navData!: object;
  @Prop({ default: 1 }) private level!: number;
  isshow: boolean=false;
  // eslint-disable-next-line
  showLang () {
    if (localStorage.locale === 'E') this.isshow = true;
  }
  // eslint-disable-next-line
  toUrl (url) {
    if (url) {
      window.location.href = url;
    }
  }
  To (n) {
    return n.Type === 1 ? '/cms/catDetail/' + n.Value.Id : n.Type === 2 ? '/CMS/content/' + n.Value.Id : n.Type === 3 ? '/RegNPay/Form/' + n.Value.Id : (n.Type === 4 && n.Value.Id) ? '/product/cat/' + n.Value.Id : n.Type === 5 ? '/product/search/+?attrs=' + JSON.stringify([{ Id: parseInt(n.Value.Id), Vals: [] }]) + '&type=0' : '';
  }
  mounted () {
    this.showLang();
  }
}
